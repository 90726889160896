import React, { useState, useEffect, useCallback } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import ContentSlide from '../../Common/Components/ContentSlide';
import Modal from '../../DesignSystem/Modal';
import SubjectIcon from '../../DesignSystem/SubjectIcon';
import Tooltip from '../../DesignSystem/Tooltip';
import SemesterSummary from './SemesterSummary';
import MilestoneContent from './MilestoneContent';

const MilestoneWizardMultiSubject = ({
  milestone,
  subjects,
  callbackFn,
  cancelFn,
  allMilestones,
  startDate,
  endDate,
}) => {
  const [updatedMilestone, setUpdatedMilestone] = useState(milestone);
  const [selectedSubject, setSelectedSubject] = useState();
  const [selectedVersion, setSelectedVersion] = useState();
  const [projectPrereqs, setProjectPrereqs] = useState([]);
  const [otherMilestones] = useState(allMilestones?.filter(m => m !== milestone));
  const [isVersionLocked, setIsVersionLocked] = useState(false);

  const { t } = useTranslation('MilestoneWizard');

  const handleProjectSelection = (project, isSelected) => {
    if (isSelected) {
      const coursesAllreadyAdded = updatedMilestone.contents.map(c => c.id) + otherMilestones.map(m => m.contents.map(c => c.id)).flat();
      setProjectPrereqs(project.prerequisite_course_ids
        .filter(courseId => !coursesAllreadyAdded.includes(courseId))
        .map(courseId => selectedVersion.contents.find(c => c.id === courseId)));
    } else {
      setProjectPrereqs([]);
    }
  };

  const addContent = content => {
    setUpdatedMilestone(ms => {
      ms.contents = [...ms.contents, content].sort((a, b) => a.order_number - b.order_number);
      return { ...ms };
    });
  };

  const removeContent = content_id => {
    setUpdatedMilestone(ms => {
      ms.contents = ms.contents.filter(c => c.id !== content_id);
      return { ...ms };
    });
  };

  const toggleContent = useCallback((content, active) => {
    if (active) addContent(content);
    else removeContent(content.id);

    if (content.content_type === 'Project') handleProjectSelection(content, active);
  }, [addContent, removeContent, handleProjectSelection]);

  const selectSubject = subject_id => {
    setSelectedSubject(subjects.find(s => s.id === parseInt(subject_id, 10)));
  };

  const selectVersion = subject_version_id => {
    setSelectedVersion(selectedSubject.subject_versions.find(sv => sv.id === parseInt(subject_version_id, 10)));
  };

  const addProjectPrerequisites = () => {
    projectPrereqs.forEach(content => {
      addContent(content);
    });
    setProjectPrereqs([]);
  };

  const dismissProjectPrerequisites = () => {
    setProjectPrereqs([]);
  };

  const renderContent = (content, preview = false, hasSubjectHeading = false) => {
    const otherMilestoneContent = otherMilestones.find(m => m.contents.find(c => c.id === content.id));
    return <MilestoneContent content={content} otherMilestoneContent={otherMilestoneContent} updatedMilestone={updatedMilestone} toggleContent={toggleContent} preview={preview} hasSubjectHeading={hasSubjectHeading} />;
  };

  const renderMultiSubjectAnnouncement = () => (
    <div className="Summary m-top-s u-textLeft" style={{ margin: '-24px' }}>
      <div className="Summary-content u-textCenter">
        <div><span className="u-bold">🚀 {t('newFeature')}: </span>{t('multiSubMilestone')}!</div>
        <div>{t('availableForNewSemesters')}. <a className="u-underline" target="_blank" href="https://support.kubicle.com/how-to-build-a-personalised-semesters-for-learners" rel="noreferrer">{` ${t('learnMore')}`}</a></div>
      </div>
    </div>
  );

  const renderContentSelection = () => (
    <>
      <div
        className="u-flexRow u-gridGap-s m-bottom-m u-alignCenter"
      >
        {selectedSubject && <SubjectIcon icon={selectedSubject.logo} colour={selectedSubject.colour} />}
        <Select
          className="Form-input u-grow1"
          classNamePrefix="react-select"
          value={selectedSubject ? { label: selectedSubject.name, value: selectedSubject.id } : null}
          onChange={option => selectSubject(option.value)}
          placeholder={t('subjectPlaceholder')}
          options={subjects.map(subject => ({ label: subject.name, value: subject.id }))}
        />
        {selectedSubject && (
          <div data-tip data-for="version-locked-message">
            <Select
              className="Form-input u-grow1"
              classNamePrefix="react-select"
              value={selectedVersion ? { label: selectedVersion.name, value: selectedVersion.id } : null}
              onChange={option => selectVersion(option.value)}
              isDisabled={isVersionLocked}
              placeholder={t('subjectVersionPlaceholder')}
              options={selectedSubject.subject_versions.map(subject_version => ({
                label: subject_version.name,
                value: subject_version.id,
                status: subject_version.status,
              }))}
              filterOption={option => option.data?.status === 'live'}
            />
          </div>
        )}
        {isVersionLocked && (
          <Tooltip
            id="version-locked-message"
            heading={t('versionLockedTitle')}
            text={t('versionLockedMessage')}
          />
        )}
      </div>
      <div
        className={`ScrollBox ScrollBox--compact u-grow1 ${projectPrereqs.length > 0 ? 'b-uiDisabled' : ''}`}
        style={{ flexBasis: 400 }}
      >
        {selectedVersion?.contents.sort((a, b) => a.order_number - b.order_number).map(content => (
          renderContent(content)
        ))}
      </div>
    </>
  );

  const renderSidebar = () => {
    if (projectPrereqs.length > 0) return renderProjectPrerequisites();
    if (selectedSubject) {
      if (updatedMilestone.contents.length > 0) return renderSelectedContents();
      return renderNoContentEmptyState();
    }
    return renderNoSubjectEmptyState();
  };

  const renderSelectedContents = () => {
    const sortedContents = updatedMilestone.contents.sort((a, b) => a.order_number - b.order_number);

    return (
      <div>
        <h4 className="u-textCenter">{t('previewMilestoneContents')}</h4>
        <div>
          {sortedContents.map((content, index) => {
            const previousContent = sortedContents[index - 1];
            const hasSameSubjectAsPrevious = previousContent?.subject_id === content.subject_id;
            const hasSubjectHeader = index === 0 || !hasSameSubjectAsPrevious;

            return renderContent(content, true, hasSubjectHeader);
          })}
        </div>
        <p className="m-left-xl Form-hint">{t('addMoreSubjects')}</p>
      </div>
    );
  };

  const renderProjectPrerequisites = () => (
    <div className="u-textCenter">
      <h4>{t('prerequisitesTitle')}</h4>
      <p>{t('prerequisiteDescription1')}</p>
      <p>{t('prerequisiteDescription2')}</p>
      {projectPrereqs.map(course => <ContentSlide key={`project-prerequisite-${course.id}`} content={course} />)}
      <div>
        <a onClick={addProjectPrerequisites} className="Button Button--primary">{t('buttonAddCourses')}</a>
        <a onClick={dismissProjectPrerequisites} className="Button Button--secondary">{t('buttonSkipPrerequisites')}</a>
      </div>
    </div>
  );

  const renderNoContentEmptyState = () => (
    <>
      {renderMultiSubjectAnnouncement()}
      <div className="EmptyState">
        <img
          className="EmptyState-image EmptyState-image--medium"
          src="/assets/empty_states/milestone_wizard_content.png"
        />
        <h4 className="EmptyState-title">{t('noContentTitle')}</h4>
        <p className="EmptyState-description u-textCenter">{t('noContentDescription')}</p>
      </div>
    </>
  );

  const renderNoSubjectEmptyState = () => (
    <>
      {renderMultiSubjectAnnouncement()}
      <div className="EmptyState">
        <img className="EmptyState-image EmptyState-image--medium" src="/assets/empty_states/milestone_wizard_subject.png" />
        <h4 className="EmptyState-title">{t('noSubjectTitle')}</h4>
        <p className="EmptyState-description u-textCenter">{t('noSubjectDescription')}</p>
      </div>
    </>
  );

  useEffect(() => {
    const { contents } = updatedMilestone;
    if (!contents.length) return;

    const initialSubject = subjects.find(s => s.id === contents[0].subject_id);
    const initialVersion = initialSubject.subject_versions.find(sv => sv.id === contents[0].subject_version_id);
    setSelectedSubject(initialSubject);
    setSelectedVersion(initialVersion);
  }, []);

  useEffect(() => {
    if (!selectedSubject) return;
    const semesterContents = [...otherMilestones, updatedMilestone].flatMap(m => m.contents);
    const semesterSubjectIds = semesterContents.map(c => c.subject_id);
    const selectedSubjectContents = semesterContents.filter(c => c.subject_id === selectedSubject.id);
    const selectedSubjectVersion = selectedSubject.subject_versions.find(sv => sv.id === parseInt(selectedSubjectContents[0]?.subject_version_id, 10)) || selectedSubject.subject_versions[0];
    setSelectedVersion(selectedSubjectVersion);
    setIsVersionLocked(semesterSubjectIds.includes(selectedSubject.id));
  }, [updatedMilestone, selectedSubject]);

  return (
    <Modal className="Modal--admin Modal--dialog" closeCallback={() => cancelFn()}>
      <h3 className="Modal-title u-textCenter">
        {t('editMilestoneWithName', { name: milestone.name })}
      </h3>
      <div className="Modal-content">
        <SemesterSummary startDate={startDate} endDate={endDate} milestones={[...otherMilestones, updatedMilestone]} className="m-top-s u-textLeft" />
        <div className="Showcase has-2-columns">
          <div className="u-flexColumn">
            {renderContentSelection()}
          </div>
          <div className="Panel m-bottom-none">
            {renderSidebar()}
          </div>
        </div>
      </div>
      <div className="Modal-actions">
        <a className="Modal-action Button Button--secondary" onClick={() => cancelFn()}>{t('buttonCancel')}</a>
        <a className={`Modal-action Button Button--primary ${projectPrereqs.length > 0 || updatedMilestone.contents.length === 0 ? 'b-uiDisabled' : ''}`} onClick={() => callbackFn(updatedMilestone)}>{t('buttonSubmit')}</a>
      </div>
    </Modal>
  );
};

export default MilestoneWizardMultiSubject;
