import React, { useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from '../../DesignSystem/Forms/Fields/Checkbox';
import ContentSlide from '../../Common/Components/ContentSlide';
import SkillLevelIcon from '../../Common/Components/SkillLevelIcon';
import Tooltip from '../../DesignSystem/Tooltip';

const MilestoneContent = ({
  content, otherMilestoneContent, updatedMilestone, toggleContent, preview = false, hasSubjectHeading = false,
}) => {
  const [detailsOpen, setDetailsOpen] = useState(false);
  const { t } = useTranslation('MilestoneWizard');

  const handleToggleDetails = event => {
    event.preventDefault();
    if (preview) return;
    setDetailsOpen(prev => !prev);
  };

  const handleContentToggle = (_, checked) => {
    if (otherMilestoneContent) return;
    toggleContent(content, checked);
  };

  return (
    <Fragment key={`milestone-content-${content.id}`}>
      <div className={`MilestoneContent u-alignStart u-flexRow  ${otherMilestoneContent ? 'b-displayDisabled' : ''} ${preview ? 'ProgressTracker-step is-incomplete' : ''} ${hasSubjectHeading ? 'u-flexWrap' : ''}`}>
        {hasSubjectHeading && (<span className="u-bold m-bottom-none m-left-xl m-top-m" style={{ flex: '0 0 100%' }}>{content.subject_name}</span>)}
        {preview ? (
          <i className="ProgressTracker-state" />
        ) : (
          <div className="m-top-ml">
            <Checkbox
              dynamic
              disabled={otherMilestoneContent != null}
              onChange={handleContentToggle}
              wrapper={false}
              value={updatedMilestone.contents.find(c => c.id === content.id) || otherMilestoneContent}
            />
          </div>
        )}
        <details className={`Accordion2 u-grow1 ${detailsOpen ? 'open m-bottom-s' : 'closed'}`} open={detailsOpen} onClick={handleToggleDetails}>
          <summary className="Accordion2-summary">
            <div
              className={`u-flexRow ${otherMilestoneContent ? 'b-displayDisabled' : ''}`}
              data-tip
              data-for={`content-locked-${content.id}`}
            >
              <ContentSlide
                content={content}
                showDetails={false}
                className={`is-link u-grow1 m-bottom-s m-top-s u-pointer ${detailsOpen ? 'details-open' : ''}`}
              >
                {preview ? (
                  <i className="fa fa-times" onClick={() => toggleContent(content, false)} />
                ) : (
                  <i className="Accordion2-toggle" />
                )}
              </ContentSlide>
            </div>
          </summary>
          <div className="Accordion2-content">
            <div className="Showcase Showcase--flex">
              <div className="Descriptor u-textBlack">
                <i
                  className={`Descriptor-icon custom-icon icon-color-black icon-${content.content_type.toLowerCase()}`}
                />
                <span className="Descriptor-text">{content.content_type}</span>
              </div>
              <div className="Descriptor u-textBlack">
                <i className="Descriptor-icon fa fa-clock-o" />
                <span className="Descriptor-text">
                  {t('durationMinutes', { minutes: content.duration })}
                </span>
              </div>
              <div className="Descriptor u-textBlack">
                <i className="Descriptor-icon custom-icon icon-cpe-cpd icon-color-black" />
                <span className="Descriptor-text">
                  {t('cpdCredits', { count: content.cpd_credits })}
                </span>
              </div>
              {content.level && (
                <SkillLevelIcon level_name={content.level} className="Descriptor u-textBlack" icon_height="15px" />
              )}
            </div>
            <p>{content.description}</p>
          </div>
        </details>
      </div>
      {otherMilestoneContent && (
        <Tooltip
          id={`content-locked-${content.id}`}
          text={t('inOtherMilestone', { content_type: content.content_type.toLowerCase(), milestone: otherMilestoneContent.name })}
        />
      )}
    </Fragment>
  );
};

export default MilestoneContent;
